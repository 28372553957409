<!--
 * @Description: 
 * @Author: 六六
 * @Date: 2021-01-20 14:58:52
 * @LastEditTime: 2021-01-27 18:40:53
 * @有问题找百度，实在不行就删库
-->
<template>
  <div :class="{ container: true, equipment: !isPhone }">
    <div class="main">
      <van-row type="flex" justify="center">
        <van-col span="22">
          <div class="prompt">
            <van-row>
              <van-col span="8">
                <div class="prompt-icon">
                  <img src="../assets/images/prompt-icon-1.png" alt="" />
                </div>
                <div class="prompt-text_1">
                  <span>分享链接</span>
                </div>
              </van-col>
              <van-col span="8">
                <div class="prompt-icon">
                  <img src="../assets/images/prompt-icon-2.png" alt="" />
                </div>
                <div class="prompt-text_2">
                  <span>获得好友助力</span>
                </div></van-col
              >
              <van-col span="8">
                <div class="prompt-icon">
                  <img src="../assets/images/prompt-icon-3.png" alt="" />
                </div>
                <div class="prompt-text_3">
                  <span>每次助力获得<br />180牛气值</span>
                </div></van-col
              >
            </van-row>
          </div>
        </van-col>
        <van-col span="22">
          <div :class="{ assistance: true, friend: !userInfo.isbenren }">
            <van-row>
              <van-col span="24">
                <div class="avatar">
                  <img :src="userInfo.headimgurl" alt="" />
                </div>
                <div class="content" v-if="userInfo.isbenren">
                  <span v-if="userInfo.helpedcount"
                    >“牛气值越来越高啦！距离大奖又近一步，加油哦~”</span
                  >
                  <span v-else
                    >“邀请好友帮忙助力0元得翻译机、键鼠套装、<br />运动手表等超值好礼”</span
                  >
                </div>
                <div class="content" v-else>
                  <span>“助人为乐是一种美德”</span>
                </div>

                <van-row v-if="userInfo.isbenren" class="row-container">
                  <van-col span="24">
                    <div class="btn">
                      <img
                        src="../assets/images/oneself-2.png"
                        alt=""
                        v-if="userInfo.helpedcount"
                        @click="shareDialog = true"
                        style="width: 65%"
                      />
                      <img
                        v-else
                        src="../assets/images/oneself-1.png"
                        alt=""
                        @click="shareDialog = true"
                        style="width: 65%"
                      />
                    </div>
                  </van-col>
                </van-row>
                <van-row class="row-container" v-else>
                  <van-col span="12">
                    <div class="btn">
                      <img
                        src="../assets/images/friend-1.png"
                        alt=""
                        style="float: right; margin-right: 5px"
                        v-if="userInfo.iscanhelp"
                        @click="handleHelp"
                      />
                      <img
                        src="../assets/images/friend-2.png"
                        alt=""
                        style="float: right; margin-right: 5px"
                        v-else
                      />
                    </div>
                  </van-col>
                  <van-col span="12">
                    <div class="btn">
                      <img
                        src="../assets/images/friend-3.png"
                        alt=""
                        style="float: left; margin-left: 5px"
                        @click="handleMyHelp"
                      />
                    </div>
                  </van-col>
                </van-row>

                <div class="bottom-number">
                  <van-row class="row-container">
                    <van-col span="6"
                      ><img
                        src="../assets/images/left.png"
                        alt=""
                        class="left"
                        style="width: 100%"
                    /></van-col>
                    <van-col span="12">
                      <span>
                        当前好友助力次数
                        <span class="number">{{ userInfo.helpedcount }}</span>
                        次
                      </span></van-col
                    >
                    <van-col span="6"
                      ><img
                        src="../assets/images/right.png"
                        alt=""
                        class="right"
                        style="width: 100%"
                    /></van-col>
                  </van-row>
                </div>
              </van-col>
            </van-row>
          </div>
        </van-col>
      </van-row>
    </div>

    <!-- 成功 -->
    <div class="dialog" @touchmove.prevent v-if="successDialog">
      <div class="dialog-wrap">
        <div class="dialog-view">
          <van-icon name="close" color="#aea99d" @click="handleCloseRef" />
          <img
            src="../assets/images/dialog-success.png"
            alt=""
            @click="handleMyHelp"
          />
        </div>
      </div>
    </div>
    <!-- 上限 -->
    <div class="dialog" @touchmove.prevent v-if="limitDialog">
      <div class="dialog-wrap">
        <div class="dialog-view">
          <van-icon name="close" color="#aea99d" @click="handleClose" />
          <img
            src="../assets/images/dialog-limit.png"
            alt=""
            @click="handleClose"
          />
        </div>
      </div>
    </div>

    <!-- 分享 -->
    <div class="dialog" @touchmove.prevent v-if="shareDialog">
      <div class="dialog-wrap-share">
        <div class="dialog-view">
          <img src="../assets/images/share.png" alt="" @click="handleClose" />
        </div>
      </div>
    </div>
    <dialog-model
      :type="dialogType"
      :dialogShow="dialogShow"
      @changeType="changeType"
      @changeShow="changeShow"
      @helpNutrans="helpNutrans"
    ></dialog-model>
  </div>
</template>

<script>
import { Jsconfig, Help } from "../util/api";
import wx from "weixin-js-sdk";
import DialogModel from "./components/dialogModel";
import { getWxPublicInfo, getUrlParam } from "../util/util";
export default {
  components: { DialogModel },
  data() {
    return {
      userInfo: {},
      sdkInfo: {},
      dialogType: 1,
      dialogShow: false,
      successDialog: false,
      limitDialog: false,
      shareDialog: false,
      link: "",
      iconUrl: "",
      niutrans: "",
      isPhone: true,
    };
  },
  watch: {},
  mounted() {
    this.isPhone = this.$store.getters["userInfo/isPhone"];
    this.getInfo();
    this.shareToFriends();
    this.shareToFriendsCircle();
  },
  methods: {
    getInfo() {
      getWxPublicInfo("", (res) => {
        if (res.error_code == -1) {
          this.dialogShow = true;
          this.dialogType = 0;
        } else if (res.error_code == -2) {
          if (getUrlParam("niutrans")) {
            window.location.href =
              this.$urls + "/zhuli?niutrans=" + getUrlParam("niutrans");
          } else {
            window.location.href = this.$urls + "/zhuli";
          }
        } else if (!getUrlParam("niutrans") && !res.isbound) {
          this.dialogShow = true;
          this.dialogType = 0;
        } else {
          this.dialogShow = false;
          this.userInfo = res;
          if (this.userInfo.isbenren) {
            this.link =
              this.$urls + "/zhuli?niutrans=" + this.userInfo.niutrans;
          } else {
            this.link =
              this.$urls + "/zhuli?niutrans=" + getUrlParam("niutrans");
          }
          this.handleShare();
        }
      });
    },
    changeType(type) {
      this.dialogType = type;
    },
    changeShow(val) {
      this.dialogShow = val;
    },
    helpNutrans(niutrans) {
      this.niutrans = niutrans;
    },
    handleMyHelp() {
      if (this.userInfo.isbound) {
        window.location.href = this.$urls + "/zhuli";
      } else {
        this.dialogShow = true;
        this.dialogType = 1;
      }
    },
    handleHelp() {
      if (this.userInfo.helpercount > 2) {
        this.limitDialog = true;
      } else {
        let obj = {
          state: this.$store.getters["userInfo/stateVal"],
          niutrans: getUrlParam("niutrans"),
        };
        Help(obj).then((res) => {
          if (res.data.error_code == 0) {
            this.successDialog = true;
          }
        });
      }
    },
    handleShare() {
      let obj = {
        niutrans: getUrlParam("niutrans"),
        url: window.location.href,
      };
      Jsconfig(obj).then((res) => {
        if (res.data.error_code == 0) {
          //配置参数
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appid, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名，
            jsApiList: [
              "updateAppMessageShareData",
              "updateTimelineShareData",
              "onMenuShareAppMessage",
              "onMenuShareTimeline",
            ],
          });

          wx.error((res) => {});
        }
      });
    },
    shareToFriends() {
      wx.ready(() => {
        wx.onMenuShareAppMessage({
          title: "只需点1下就能帮我助力抽盲盒，你也可参加", // 分享标题
          desc: "小牛翻译机、键鼠套装等好礼", // 分享描述
          link: this.link,
          // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.$urls + "/NiuTransWeChatPublic/shareimgae/share.png", // 分享图标
          type: "", // 分享类型,music、video或link，不填默认为link
          dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
          success: function () {},
        });
      });
    },
    shareToFriendsCircle() {
      wx.ready(() => {
        wx.onMenuShareTimeline({
          title: "只需点1下就能帮我助力抽盲盒，你也可参加", // 分享标题
          link: this.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.$urls + "/NiuTransWeChatPublic/shareimgae/share.png", // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          },
        });
      });
    },
    handleCloseRef() {
      this.successDialog = false;
      this.getInfo();
    },
    handleClose() {
      this.limitDialog = false;
      this.shareDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  background: url("../assets/images/background.png") no-repeat;
  background-size: 100%;
  z-index: -100;
  .main {
    height: 100%;
    .prompt {
      height: 140px;
      margin-top: 110%;
      border-radius: 10px;
      background: url("../assets/images/prompt.png") no-repeat;
      background-size: 100%;
      padding: 50px 20px 0;
      .prompt-icon {
        width: 65%;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
      .prompt-text_1,
      .prompt-text_2 {
        margin-top: 15px;
        font-weight: 600;
      }
      .prompt-text_3 {
        margin-top: 5px;
        font-weight: 600;
      }
    }
    .friend {
      background-image: url("../assets/images/friend.png") !important;
    }
    .assistance {
      height: 265px;
      border-radius: 10px;
      background: url("../assets/images/assistance.png") no-repeat;
      background-size: 100%;
      padding: 70px 20px 0;
      .avatar {
        img {
          width: 45px;
          border: 3PX solid #fbdf80;
          border-radius: 50%;
        }
      }
      .content {
        font-weight: 600;
        margin-top: 10px;
        line-height: 1.5;
      }
      .row-container {
        margin-top: 20px;
      }
      .btn {
        img {
          width: 80%;
        }
      }
      .bottom-number {
        margin-top: 28px;
        span {
          // margin: 0 10px;
          font-weight: 600;
          .number {
            color: #c23343;
          }
        }
      }
    }
  }
}
.equipment {
  .dialog {
    max-width: 640PX !important;
    margin: 0 auto !important;
  }
}
.dialog {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  .dialog-wrap {
    width: 60%;
    // height: 100px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .dialog-view {
      position: relative;
      i {
        font-size: 22px;
        position: absolute;
        right: 0;
      }
      img {
        width: 100%;
      }
    }
  }
  .dialog-wrap-share {
    width: 75%;
    margin-left: 16%;
    margin-top: 15%;
    .dialog-view {
      img {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .prompt {
    height: 185px !important;
    padding: 80px 20px 0 !important;
  }
  .assistance {
    height: 380px !important;
    padding: 95px 20px 0 !important;
    .avatar {
      img {
        width: 70px !important;
      }
    }
    .content {
      margin-top: 20px !important;
    }
    .btn {
      img {
        width: 65% !important;
      }
    }
  }
}
@media only screen and (min-width: 561px) and (max-width: 767px) {
  .equipment {
    .prompt {
      height: 160px !important;
      padding: 50px 20px 0 !important;
    }
    .assistance {
      height: 300px !important;
      padding: 80px 20px 0 !important;
    }
  }
}
@media screen and (min-width: 768px) {
  .equipment {
    .prompt {
      height: 160px !important;
      padding: 60px 20px 0 !important;
    }
    .assistance {
      height: 300px !important;
      padding: 95px 20px 0 !important;
      .avatar {
        img {
          width: 70px !important;
        }
      }
      .content {
        margin-top: 20px !important;
      }
      .btn {
        img {
          width: 65% !important;
        }
      }
    }
  }
}
</style>
